import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from './styles.module.scss'
import BottomModal from "../../components/BottomModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import HelperContext from "../../../redux/HelperContext";
import { fetchUserData, refreshOrders, updateAddress, updateUserData } from "../../../redux/reducers/user";
import { useCoolDown } from "../../../components/utils/hooks";
import moment from "moment";
import { cartClear } from "../../../redux/reducers/cart";
import sendRequest from "../../../api";
import Products from "../../../screens/Cart/components/Products";
import AddressBlock from "../../../screens/Cart/components/AddressBlock";
import clock from "../../../assets/images/cart/clock.svg";
import SText from "../../../components/SText";
import Colors from "../../../components/utils/Colors";
import { getCategoryName, prettyDate, roundPrice } from "../../../components/utils/other";
import arrow from "../../../assets/images/userAccount/personalData/changeAddress.svg";
import PaymentAndPromocode, {
    BonusesSelector,
    CardModal,
    PromoModal
} from "../../../screens/Cart/components/PaymentAndPromocode";
import SlotsModal from "../../../screens/Cart/components/SlotsModal";
import OrderBtn from "../../../screens/Cart/components/OrderBtn";
import AddCard from "../../../screens/AddCardScreen/AddCard";
import { useQueryParams } from "../../../components/utils/ModalSpectator";
import phoneSVG from '../../../assets/images/cart/phoneIcon.svg'
import LocationSVG from '../../../assets/images/cart/mapIcon.svg'
import ClockSVG from '../../../assets/images/cart/clockIcon.svg'
import CardSVG from '../../../assets/images/cart/cardIcon.svg'
import StarSVG from '../../../assets/images/cart/starIcon.svg'
import angle from '../../../assets/images/cart/angle.svg'
import { getCookie } from "../../utils";
import InputMask from "react-input-mask";

const Cart = ({ onClose }) => {

    const cartState = useSelector(state => state.cart)
    const cart = cartState.order
    const user = useSelector(state => state.user)
    const prods = useSelector(state => state.catalogue.products)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const deliverySlots = useSelector(state => state.deliverySlots)
    const selectedSlot = deliverySlots.selectedSlot
    const [disabled, setDisabled] = useState(false)
    const [tips, setTips] = useState({ courier: 0, assembler: 0 })
    const [orderId, setOrderId] = useState(null)
    const [pay, setPay] = useState(false)
    const [sbp, setSbp] = useState(false)
    const [sberPay, setSberPay] = useState(false)
    const [orderButtonClicked, setOrderButtonClicked] = useState(false)
    const [orderResponse, setOrderResponse] = useState({})

    const {
        totals,
        delivery,
        promoCodeData,
        useBonuses,
        selectedMethod,
        setUseBonuses,
        paymentMethods,
        resetPromoCode
    } = useContext(HelperContext)


    useEffect(() => {
        if (selectedMethod === 'cash') {
            setTips({ courier: 0, assembler: 0 })
        }
    }, [selectedMethod])

    useEffect(() => {
        if (typeof window.yaCounter49140634 !== "undefined") {
            window.yaCounter49140634.reachGoal('makeOrder');
        }
    }, [])


    const [errors, setErrors] = useState([])

    const [userInfo, setUserInfo] = useState({
        name: user.name,
        address: user.address,
        phone: user.phone,
    })

    useEffect(() => {
        setUserInfo({
            name: user.name,
            address: user.address,
            phone: user.phone,
        })
    }, [user.name, user.address, user.phone])

    function compareUserInfo() {
        const isSameUsr = (
            userInfo.name === user.name &&
            userInfo.phone === user.phone
        )

        const isSameAddress = (
            userInfo.address.number === user.address.number &&
            userInfo.address.entrance === user.address.entrance &&
            userInfo.address.floor === user.address.floor &&
            userInfo.address.comment === user.address.comment
        )

        if (!isSameUsr)
            dispatch(updateUserData({ name: userInfo.name, phone: userInfo.phone }))
        if (!isSameAddress)
            dispatch(updateAddress(userInfo.address))
    }

    const delayedCompareFn = useCoolDown(compareUserInfo, 1000)

    useEffect(() => {
        delayedCompareFn()
    }, [userInfo])

    useEffect(() => {
        if (cart?.length === 0)
            navigate(-1)
    }, [cart])

    function preOrderCheck() {
        let foundErrors = []
        /*        if (!userInfo.name)
                    foundErrors.push('name')*/

        if (!userInfo.phone || userInfo.phone.length < 16)
            foundErrors.push('phone')

        if (
            !selectedSlot.date ||
            !selectedSlot.time ||
            (moment(selectedSlot.date + ' ' + selectedSlot.time.split('-')[1], 'YYYY-MM-DD HH:mm').isBefore(moment()) && selectedSlot.time.split('-')[1] !== '00:00')
        )
            foundErrors.push('slot')

        if (!user.address?.gps?.[0])
            foundErrors.push('address')

        setErrors(foundErrors)
    }

    useEffect(() => {
        preOrderCheck()
    }, [userInfo, selectedSlot, user.address])

    async function onReady(id, security, newUser, number) {
        if (!newUser) {
            window.orderId = id
            window.security = security
        } else if (newUser) {
            window.newUserOrderId = number
            window.newUserPhone = userInfo.phone
        }
        navigate('/')
        setDisabled(false)
    }

    async function onSubmit() {
        setOrderButtonClicked(true)

        if (errors.includes('phone')) {
            window.alert('Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð²Ð°Ñˆ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°')
            return
        }

        /*        if (errors.includes('name')) {
                    window.alert('Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð²Ð°ÑˆÐµ Ð¸Ð¼Ñ')
                    return
                }*/
        if (errors.includes('address')) {
            window.alert('Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð°Ð´Ñ€ÐµÑ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸')
            return
        }
        if (errors.includes('slot')) {
            window.alert('Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð²Ñ€ÐµÐ¼Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸')
            return
        }
        if (disabled) return

        const selectedPaymentMethod = paymentMethods.find(m => m.type === selectedMethod)

        setDisabled(true)
        const payload = {
            apikey: user.apikey,
            products: cartState.items,
            name: userInfo.name,
            email: user.email || '',
            adress: user.address.name,
            phone: user.phone,
            coupon: promoCodeData.name || '',
            bonuses: useBonuses ? totals.availableBonuses : 0,
            date: selectedSlot.date,
            time: selectedSlot.time,
            delivery: 2,
            source: 0,
            payment: selectedPaymentMethod?.value == 9 ? 3 : selectedPaymentMethod?.value || 1,
            point: false,
            comment: userInfo.address.comment,
            appartment: userInfo.address.number,
            floor: userInfo.address.floor,
            entrance: userInfo.address.entrance,
            mkad: deliverySlots.data.km, // todo ÑƒÐ±Ñ€Ð°Ñ‚ÑŒ Ð½Ð° Ð±ÑÐºÐµ
            delivery_addon: Math.max(0, delivery.regularPrice - delivery.promoCodeDiscount) || false, // todo ÑƒÐ±Ñ€Ð°Ñ‚ÑŒ Ð½Ð° Ð±ÑÐºÐµ
            region: deliverySlots.data.region, // todo ÑƒÐ±Ñ€Ð°Ñ‚ÑŒ Ð½Ð° Ð±ÑÐºÐµ
            gps: user.address.gps,
            card: false,
            confirmationRequired: true,
            test_case: getCookie().test_case,
            build: 1,
            sid: deliverySlots.data?.sid || null,
            cartTimings: cartState.timings,
        }

        const response = await sendRequest('submitOrderFromApp_v2', payload)
        // const response = {
        //     number: 'test',
        //     order: 1311557
        // }

        setOrderResponse(response)

        let products = cartState.order.map(id => {
            const prod = prods[id]
            if (prod) {
                return {
                    'id': prod.id,
                    'name': prod.title,
                    'price': prod.price,
                    'brand': prod.brand || null,
                    'category': getCategoryName(prod.all_groups[0]) || null,
                    'quantity': cartState.items[parseInt(id)]
                }
            } else return {}
        })

        let items = cartState.order.map(id => {
            const prod = prods[id]
            if (prod) {
                return {
                    'item_id': prod.id,
                    'item_name': prod.title,
                    'price': prod.price,
                    'item_brand': prod.brand || null,
                    'item_category': getCategoryName(prod.all_groups[0]) || null,
                    'item_category2': getCategoryName(prod.all_groups[1]) || null,
                    'item_category3': getCategoryName(prod.all_groups[2]) || null,
                    'item_category4': getCategoryName(prod.all_groups[3]) || null,
                    'item_category5': getCategoryName(prod.all_groups[4]) || null,
                    'quantity': cartState.items[parseInt(id)],
                    'discount': prod.old_price ? (prod.old_price - prod.price) : null
                }
            } else return {}
        })

        if (typeof window.yaCounter49140634 !== "undefined") {
            window.yaCounter49140634.reachGoal('MADE_A_PURCHASE');
        }

        try {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                'ecommerce': {
                    'currencyCode': 'RUB',
                    'purchase': {
                        'actionField': {
                            'id': response.number,
                            'revenue': `${totals.finalTotals.actual}`,
                            'shipping': `${delivery.regularPrice}`,
                            'coupon': promoCodeData.name || null
                        },
                        'products': products,
                    },
                    'items': items,
                },
                'event': 'gtm-ee-event',
                'event-GA4': 'purchase',
                'gtm-ee-event-category': 'Enhanced Ecommerce',
                'gtm-ee-event-action': 'Purchase',
                'gtm-ee-event-non-interaction': 'False',
                'payment_type': selectedPaymentMethod?.value === 1 ? 'cash' : selectedPaymentMethod?.value === 3 ? 'card' : null,
                'order_type': user.orders.total > 0 ? 'repeat' : 'new',
            });

        } catch (error) {
            console.log(error)
        }

        if (!response.order) {
            window.alert('ÐŸÑ€Ð¾Ð¸Ð·Ð¾ÑˆÐ»Ð° Ð¾ÑˆÐ¸Ð±ÐºÐ°')
            setDisabled(false)
            return
        }
        await dispatch(fetchUserData())
        await dispatch(refreshOrders())
        await dispatch(cartClear())

        resetPromoCode()

        if (selectedPaymentMethod.cardId) {
            const payload = {
                order: response.order,
                card: selectedPaymentMethod.cardId,
                apikey: user.apikey
            }
            await sendRequest('addCardToOrder', payload)
        }

        if ((selectedPaymentMethod.value === 3 || selectedPaymentMethod.value === 13 || selectedPaymentMethod.value === 9) && !selectedPaymentMethod.cardId) {
            if (selectedPaymentMethod.value === 13) {
                setSbp(true)
            }
            if (selectedPaymentMethod.value === 9) {
                setSberPay(true)
            }
            setPay(true)
            setOrderId(response.order)
            setDisabled(false)
            return
        }

        return await onReady(response.order, response.security, response.newUser, response.number)
    }

    // if (cart.length === 0) {
    //     navigate('/')
    //     return null
    // }


    return <BottomModal onClose={ onClose }>
        <Products mobile />
        <BonusesSelector onSwitch={ setUseBonuses } isOn={ useBonuses } availableBonuses={ totals.availableBonuses } />
        <div className={ styles.priceBlock }>
            <div><SText size={ 14 } weight={ 500 } lineHeight={ 17 }>{ 'ÐšÐ¾Ñ€Ð·Ð¸Ð½Ð°' }</SText> <SText size={ 14 } weight={ 500 }
                lineHeight={ 17 }>{ `${roundPrice(totals.cart.old)} â‚½` }</SText>
            </div>
            <div><SText size={ 14 } weight={ 500 } lineHeight={ 17 }>{ 'Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ°' }</SText> <SText size={ 14 } weight={ 500 }
                lineHeight={ 17 }>{ `${roundPrice(totals.deliveryPrice)} â‚½` }</SText>
            </div>
            <div><SText size={ 14 } weight={ 500 } lineHeight={ 17 }>{ 'Ð¡ÐºÐ¸Ð´ÐºÐ°' }</SText> <SText size={ 14 } weight={ 500 }
                lineHeight={ 17 }>{ `${roundPrice(totals.finalTotals.actual - totals.finalTotals.old)} â‚½` }</SText>
            </div>
            <div style={ { marginBottom: 15 } }><SText size={ 14 } weight={ 900 } lineHeight={ 18 }>{ 'Ð˜Ð¢ÐžÐ“Ðž' }</SText> <SText
                size={ 14 } weight={ 900 }
                lineHeight={ 18 }>{ `${roundPrice(totals.finalTotals.actual)} â‚½` }</SText>
            </div>

        </div>
        <MenuV2 orderButtonClicked={ orderButtonClicked } userInfo={ userInfo } errors={ errors } setUserInfo={ setUserInfo } />
        <div className={ styles.payBtnWrapper }>
            <OrderBtn disabled={ (!userInfo.phone || userInfo.phone.length < 16) || !user.address?.gps?.[0] || errors.length !== 0 || disabled } onPress={ onSubmit } />
        </div>
        { (pay && orderId) &&
            <AddCard sberPay={ sberPay } sbp={ sbp } orderId={ orderId } onSuccess={ () => onReady(orderResponse.order, orderResponse.security, orderResponse.newUser, orderResponse.number) } onClose={ () => setPay(false) } /> }
    </BottomModal>
}

function getSelectedMethodLabel(selectedMethod, paymentMethods) {
    const methodData = paymentMethods.find(method => method.type === selectedMethod)

    if (selectedMethod.includes('card_')) {
        const cardLast = methodData?.last || ''
        return `ÐžÐ¿Ð»Ð°Ñ‚Ð° ÐºÐ°Ñ€Ñ‚Ð¾Ð¹ **** ${cardLast}`
    }
    if (!methodData) {
        return 'ÐžÐ¿Ð»Ð°Ñ‚Ð°'
    }
    return methodData?.label

}

const MenuV2 = ({ userInfo, setUserInfo, errors, orderButtonClicked }) => {
    const address = useSelector(state => state.user.address);
    const user = useSelector(state => state.user)

    const [promoModal, setPromoModal] = useState(false)
    const [cardsModal, setCardsModal] = useState(false)
    const [slotsModal, setSlotsModal] = useState(false)
    const [addressModal, setAddressModal] = useState(false)
    const [phoneModal, setPhoneModal] = useState(false)

    const deliverySlots = useSelector(state => state.deliverySlots)
    const {
        paymentMethods,
        selectedMethod,
        promoCodeData,
        resetPromoCode
    } = useContext(HelperContext)

    const selectedSlot = deliverySlots.selectedSlot

    const hasAddress = !!address?.name;

    const items = [
        {
            icon: phoneSVG,
            mainText: user.phone ? user.phone : 'Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°',
            onClick: () => {
                setPhoneModal(true)
            },
            warning: (!user.phone && orderButtonClicked),
            mainTextColor: (!user.phone && orderButtonClicked) ? '#FF5252' : '#383838',
        },
        {
            icon: LocationSVG,
            mainText: hasAddress ? address.name : 'Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð°Ð´Ñ€ÐµÑ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸',
            subText: hasAddress && (address.comment || (address.number ? 'ÐžÑÑ‚Ð°Ð²Ð¸Ñ‚ÑŒ ÐºÐ¾Ð¼Ð¼ÐµÐ½Ñ‚Ð°Ñ€Ð¸Ð¹' : 'ÐžÑÑ‚Ð°Ð²Ð¸Ñ‚ÑŒ ÐºÐ¾Ð¼Ð¼ÐµÐ½Ñ‚Ð°Ñ€Ð¸Ð¹ Ð¸ ÑƒÐºÐ°Ð·Ð°Ñ‚ÑŒ Ð½Ð¾Ð¼ÐµÑ€ ÐºÐ²Ð°Ñ€Ñ‚Ð¸Ñ€Ñ‹')),
            onClick: () => {
                setAddressModal(true)
            },
            warning: !hasAddress && orderButtonClicked,
            mainTextColor: !hasAddress && orderButtonClicked ? '#FF5252' : '#383838',
        },
        {
            icon: ClockSVG,
            mainText: selectedSlot?.time
                ? `${prettyDate(
                    moment(selectedSlot.date, 'YYYY-MM-DD'), true)} c ${selectedSlot.time.replace('-', ' Ð´Ð¾ ')}`
                : 'Ð§Ñ‚Ð¾Ð± Ð²Ñ‹Ð±Ñ€Ð°Ñ‚ÑŒ Ð²Ñ€ÐµÐ¼Ñ Ð´Ð¾ÑÑ‚Ð°Ð²ÐºÐ¸, Ð½ÐµÐ¾Ð±Ñ…Ð¾Ð´Ð¸Ð¼Ð¾ ÑƒÐºÐ°Ð·Ð°Ñ‚ÑŒ Ð²Ð°Ñˆ Ð°Ð´Ñ€ÐµÑ',
            onClick: () => {
                setSlotsModal(true)
            },
            warning: !selectedSlot.date && orderButtonClicked,
        },
        {
            icon: CardSVG,
            mainText: getSelectedMethodLabel(selectedMethod, paymentMethods),
            onClick: () => {
                setCardsModal(true)
            }
        },
        {
            icon: StarSVG,
            mainText: promoCodeData?.name ? 'ÐŸÑ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´ Ð¿Ñ€Ð¸Ð¼ÐµÐ½Ñ‘Ð½' : 'ÐŸÑ€Ð¾Ð¼Ð¾ÐºÐ¾Ð´',
            onClick: () => {
                setPromoModal(true)
            },
            activePromo: !!promoCodeData?.name,
            resetPromoCode
        },
    ]

    return <div className={ styles.container }>
        { items.map((item, index) => {
            if (index === 0 && user.apikey) return null
            return <>
                { index !== 0 && <div key={ index + '_line' } style={ styles.line } /> }
                <Item key={ index } { ...item } />
            </>
        }) }
        { cardsModal && <CardModal onClose={ () => setCardsModal(false) } /> }
        { addressModal &&
            <AddressBlock orderButtonClicked={ orderButtonClicked } data={ userInfo } errors={ errors } setter={ setUserInfo } onClose={ () => setAddressModal(false) } /> }
        { promoModal && <PromoModal onClose={ () => setPromoModal(false) } /> }
        { slotsModal && <SlotsModal onClose={ () => setSlotsModal(false) } /> }
        { phoneModal &&
            <PhoneModal orderButtonClicked={ orderButtonClicked } errors={ errors } phone={ userInfo.phone } setUserInfo={ setUserInfo } onClose={ () => setPhoneModal(false) } /> }

    </div>
}

const Item = ({
    icon, mainText, subText, onClick = () => {
    }, activePromo, resetPromoCode, warning, mainTextColor
}) => {

    return <div onClick={ onClick } className={ styles.itemWrapper }>
        <div style={ { display: 'flex', alignItems: 'center', gap: 12 } }>
            <img src={ icon } />
            <div>
                <SText size={ 14 } weight={ 500 } lineHeight={ 17 }
                    color={ mainTextColor ? mainTextColor : '#383838' }>{ mainText }</SText>
                { subText &&
                    <div className={ styles.subText }><SText color={ '#adadad' } size={ 11 } weight={ 500 } lineHeight={ 14 }>{ subText }</SText></div> }
            </div>
        </div>
        { activePromo ? <SText size={ 14 } weight={ 500 } color={ '#bfbfbf' } onClick={ (e) => {
            e.stopPropagation()
            resetPromoCode()
        } } style={ { borderBottom: '1px solid #bfbfbf' } }>{ 'ÐžÑ‚Ð¼ÐµÐ½Ð¸Ñ‚ÑŒ' }</SText>
            : warning ? <div style={ {
                height: 16,
                width: 16,
                background: Colors.red,
                borderRadius: '50%',
                display: 'grid',
                placeItems: 'center',
                flexShrink: 0,
            } }><SText color={ '#fff' } size={ 11 } weight={ 700 }>{ '!' }</SText></div> : <img src={ angle } /> }
    </div>
}

const PhoneModal = ({ onClose, phone, setUserInfo, errors, orderButtonClicked }) => {

    const [value, setValue] = useState(phone)

    const onBtnClick = () => {
        setUserInfo(prev => ({ ...prev, phone: value }))
        setTimeout(onClose, 200)
    }

    return <BottomModal maxHeight={ 455 } onClose={ onClose }>
        <div style={ { padding: '0 20px' } }>
            <SText size={ 16 } weight={ 900 }>{ 'Ð’Ð²ÐµÐ´Ð¸Ñ‚Ðµ Ð²Ð°Ñˆ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°' }</SText>
        </div>
        <div className={ styles.inputRow }>
            <InputMask mask={ '+7-999-999-99-99' }
                maskChar={ '' }
                alwaysShowMask={ false }
                value={ value }
                onChange={ (e) => setValue(e.target.value) }>
                { (inputProps) => (<input
                    placeholder={ 'Ð£ÐºÐ°Ð¶Ð¸Ñ‚Ðµ Ð²Ð°Ñˆ Ð½Ð¾Ð¼ÐµÑ€ Ñ‚ÐµÐ»ÐµÑ„Ð¾Ð½Ð°' }
                    type={ 'cel-phone' }
                    className={ errors.includes('phone') && orderButtonClicked && styles.greenPlaceholder }
                    defaultValue={ '' }
                    inputMode={ 'tel' }
                    maxLength={ 16 }
                    { ...inputProps }
                />) }
            </InputMask>
        </div>
        <div style={ { padding: '0 20px' } }>
            <button disabled={ value.length !== 16 } style={ { opacity: value.length !== 16 ? 0.5 : 1 } } onClick={ onBtnClick }
                className={ styles.greenBtn }>
                <SText size={ 16 } weight={ 500 } color={ '#fff' }>{ 'Ð“Ð¾Ñ‚Ð¾Ð²Ð¾' }</SText>
            </button>
        </div>
    </BottomModal>
}

export default Cart
